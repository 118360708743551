<template>
  <div class="container">
    <div class="content-box">
      <h1>Thank you</h1>
      <div>You have successfully subscribed to Guilds & Blades early <strong>BETA phase</strong>. We'll let you know when it's ready!</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  metaInfo () {
    return {
      title: 'Guilds & Blades - Subscription Successful'
    }
  }
}
</script>

<style scoped lang="scss">

</style>
